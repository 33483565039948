.song-preview {
    position: absolute;
    bottom: 20px;
    right: 20px;
    display: flex;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    max-width: 300px;
    cursor: pointer;
}

.preview-image {
    width: 50px;
    height: 50px;
    border-radius: 4px;
    margin-right: 10px;
}

.song-info {
    flex-grow: 1;
    flex-direction: column;
}

.song-name {
    margin: 0;
    font-size: 16px;
    font-weight: bold;
}

.date-venue {
    margin: 0;
    font-size: 12px;
    color: #ccc;
}

.waveform {
    display: flex;
    align-items: flex-end;
    height: 20px;
    width: 30px;
    margin-left: 10px;
}

.bar {
    width: 3px;
    background-color: #1db954;
    margin-right: 3px;
    animation: sound 0.5s linear infinite alternate;
}

.bar:nth-child(1) {
    animation-duration: 0.2s;
}

.bar:nth-child(2) {
    animation-duration: 0.15s;
}

.bar:nth-child(3) {
    animation-duration: 0.3s;
}

@keyframes sound {
    0% {
        height: 3px;
    }
    100% {
        height: 15px;
    }
}